:root {
  /* https://css-tricks.com/updating-a-css-variable-with-javascript/ */
  /* document.documentElement.style.setProperty('--header-background-color', 'rgb(16, 56, 116)'); */
  /* CSS colors: https://www.w3schools.com/cssref/css_colors.asp */


  /* app structural elements' sizes */
  --header-min-height: 10vh;
  --header-max-height: 10vh;
  --app-min-height: 90vh;


  /* TEMP - refactor and move to project styles */
  --default-desousernamelist-background-color:  rgb(140, 140, 140);
  --default-desousernamelist-title-row-background-color:  rgb(120, 120, 120);
  --default-desousernamelist-border-color:  rgb(90, 90, 90);

  --colorx: rgb(117, 92, 131);
  --clory: rgb(95, 170, 176);
}

.App {
  text-align: center;
}
.App-logo {
  height: 8vmin;
  pointer-events: none;
}
.ProgressIndicator {
  height: 8vmin;
  pointer-events: none;
}
@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 120s linear;
  }
}
.App-header {
  background-color: var(--header-background-color);
  min-height: var(--header-min-height);
  max-height: var(--header-max-height);
  min-width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: var(--header-text-color);
}
.Landing {
  background-color: var(--landing-background-color);
  min-height: var(--app-min-height);
  min-width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: calc(12px + 2vmin);
  color: var(--app-text-color);
}
.LandingPageImageContainer {
  border: var(--landing-page-image-container-border-settings);
  background-color: var(--landing-page-image-container-background-color);
  padding: 0px;
  margin: 0px;
  border-radius: 5px;
}
.ColumnLeft {
  background-color: var(--left-menu-background-color);
  min-height: 100vh;
  min-width: 0vw;
  display: flex;
  flex-direction: column;
  align-items: left;
  font-size: calc(6px + 2vmin);
  color: var(--app-text-color);
}
.ColumnCenter {
  background-color: var(--app-background-color);
  min-height: 90vh;
  min-width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: calc(10px + 2vmin);
  color: var(--app-text-color);
}
.ColumnCenterProjectSeries {
  font-size: calc(10px + 1vmin);
}
.ColumnCenterAuthorizeMessageDone {
  font-size: calc(6px + 1vmin);
  font-weight: bold;
  color: var(--info-message-success-background-color);
}
.ColumnCenterAuthorizeMessageNOTDone {
  font-size: calc(6px + 1vmin);
  font-weight: bold;
  color: var(--info-message-failure-background-color);
}
.ColumnCenterSeriesDescription {
  font-size: calc(6px + 1vmin);
  padding-left: calc(20px + 1vmin);
  padding-right: calc(20px + 1vmin);
  padding-top: 0px;
  padding-bottom: 30px;
  text-align: left;
  overflow-wrap: break-word;
  word-wrap: break-word;
  white-space: pre-line;
}
.ColumnRight {
  background-color: var(--right-column-background-color);
  min-height: 90vh;
  min-width: 0vw;
  display: flex;
  flex-direction: column;
  align-items: left;
  font-size: calc(10px + 2vmin);
  color: var(--app-text-color);
}
.nft-item-grid {
  min-width: 100vw;
  background-color: var(--nft-item-grid-background-color);
}
.nft-item-grid-gradient {
  min-width: 100vw;
  background: linear-gradient(0deg, var(--nft-item-grid-background-color) 0%, var(--app-background-color) 100%);
}
.ImageContainer {
  display: flex;
  justify-content: center;
  flex-direction: row;
  flex-wrap: wrap;
  grid-column-gap: 25px;
  grid-row-gap: 15px;
  font-size: calc(10px + 1vmin);
}
.MergeMintContainer {
  font-size: calc(9px + 1vmin);
}
.ImageMintMergeItemLeft {
  font-size: calc(4px + 1vmin);
  padding-right: 5px;
  width: 100%;
}
.ImageMintMergeItemRight {
  font-size: calc(4px + 1vmin);
  padding-left: 5px;
  width: 100%;
}
.TraitsTableMintMergeTable {
  border: var(--traits-table-mint-merge-border-settings);
  border-collapse: collapse;
  padding-left: 2px;
  padding-right: 2px;
  padding-top: 0px;
  padding-bottom: 0px;
  border-spacing: 0px;
  vertical-align: center;
  width: 100%;
}
.TraitsTableMintMergeData {
  border: var(--traits-table-mint-merge-border-settings);
  border-collapse: collapse;
  padding-left: 2px;
  padding-right: 2px;
  padding-top: 0px;
  padding-bottom: 0px;
  border-spacing: 0px;
  vertical-align: center;
  font-size: calc(4px + 1vmin);
}
.TraitsTableMintMergeImage {
  border: var(--traits-table-mint-merge-border-settings);
  border-collapse: collapse;
  padding-left: 2px;
  padding-right: 2px;
  padding-top: 2px;
  padding-bottom: 2px;
  border-spacing: 0px;
  vertical-align: top;
}
.MergeMintNotesList {
  font-weight: normal;
  text-align: left;
  justify-content: left;
}
.ProjectList {
  display: flex;
  justify-content: center;
  flex-direction: row;
  flex-wrap: wrap;
  grid-column-gap: 25px;
  grid-row-gap: 15px;
  font-size: calc(10px + 1vmin);
}
.ProjectListItem {
  display: flex;
  justify-content: center;
  flex-direction: row;
  flex-wrap: wrap;
  grid-column-gap: 25px;
  grid-row-gap: 15px;
  flex: 1 1 0px;
}
.SeriesList {
  display: flex;
  justify-content: center;
  flex-direction: row;
  flex-wrap: wrap;
  grid-column-gap: 25px;
  grid-row-gap: 15px;
  font-size: calc(10px + 1vmin);
}
.ImageItem {
  font-size: calc(6px + 1vmin);
}
.MergeMintedImatgeItemSources {
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.BigImageDisplay {
  position: fixed;
  bottom: 1rem;
  left: 1rem;
  padding: 5px;
  text-align: left;
  border-radius: 5px;
  background-color: var(--traits-message-standard-background-color);
  font-size: calc(6px + 1vmin);
}
.TraitsMessage {
  display: flex;
  justify-content: center;
  flex-direction: row;
  flex-wrap: wrap;
  text-align: left;
  margin-top: 10px;
  margin-bottom: 20px;
  background-color: var(--nft-item-grid-background-color);
  font-size: calc(2px + 1vmin);
}
.TraitsTable {
  border: var(--traits-table-standard-border-settings);
  border-collapse: collapse;
  padding-left: 2px;
  padding-right: 2px;
  padding-top: 0px;
  padding-bottom: 0px;
  border-spacing: 0px;
}
.MintPassTable {
  border: var(--mintpass-table-standard-border-settings);
  border-collapse: collapse;
  padding-left: 2px;
  padding-right: 2px;
  padding-top: 0px;
  padding-bottom: 0px;
  border-spacing: 0px;
  font-size: calc(4px + 1vmin);
  text-align: center;
}
.PromptText {
  font-size: calc(8px + 1vmin);
}
.PromptTextMergeMinting {
  font-size: calc(8px + 1vmin);
  font-weight: bolder;
  color: var(--app-text-color);
}
.PromptTextTraitGuessingSeries {
  font-size: calc(8px + 1vmin);
  font-weight: bolder;
  color: var(--app-text-color);
}
.NFTSortingOrFilteringCombo {
  font-size: calc(8px + 1vmin);
  font-weight: normal;
  color: black;
  margin: 15px;
  min-width: 200px;
  max-width: 500px;
}
.TraitGuessingChooserCombo {
  font-size: calc(8px + 1vmin);
  font-weight: normal;
  color: black;
  margin: 15px;
  min-width: 200px;
  max-width: 500px;
}
.App-link {
  color: var(--app-link-text-color);
  align-self: center;
  font-size: calc(10px + 2vmin);
}
.user-container {
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  flex-direction: row;
  padding: 2px;
}
.user-container-wrapping {
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  flex-direction: row;
  padding: 2px;
  flex-wrap: wrap;
}
.user-container-internal {
  display: contents;
  justify-content: center;
  align-items: center;
  align-content: center;
  flex-direction: row;
  padding: 2px;
}
.user-container-leftaligned {
  display: flex;
  justify-content: left;
  align-items: center;
  align-content: center;
  flex-direction: row;
  padding: 2px;
}
.LoggedInUserContainer {
  max-height: 100%;
  padding: 0.7em;
  border: var(--logged-in-user-container-border-settings);
  align-self: center;
  background-color: var(--logged-in-user-container-background-color);
  border-radius: 1em;
  font-size: calc(8px + 1vmin);
  text-align: center;
  display: grid;
  grid-template-columns: 1fr 4fr 1fr;
  grid-gap: 1px;
  overflow: visible;
}
.slidingleftmenubar {
  position: fixed;
  top: 0rem;
  left: 0rem;
  padding: 0rem;
  min-width: 15vw;
}
.versioninfo {
  position: fixed;
  bottom: 0.1rem;
  left: 0.1rem;
  padding: 0.1rem;
  border-radius: 1px;
  font-size: calc(4px + 1vmin);
  /*color: rgb(95, 95, 95);*/
  color: var(--app-text-color);
}
.infomessage {
  position:fixed;
  bottom: 1rem;
  right: 1rem;
  padding: 1rem;
  border-radius: 5px;
  background-color: var(--info-message-success-background-color);
  font-size: calc(7px + 1vmin);
  color: var(--app-text-color);
}
.infomessageerror {
  position:fixed;
  bottom: 1rem;
  right: 1rem;
  padding: 1rem;
  border-radius: 5px;
  background-color: var(--info-message-failure-background-color);
  font-size: calc(10px + 1vmin);
  color: var(--app-text-color);
}
.DeSoUsernameSelectList {
  position: fixed;
  left: var(--DeSoUsernameSelectList-left);
  top: var(--DeSoUsernameSelectList-top);
  bottom: var(--DeSoUsernameSelectList-bottom);
  width: var(--DeSoUsernameSelectList-width);
  height: var(--DeSoUsernameSelectList-height);
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 5px;
  padding-bottom: 5px;
  border-radius: 1px;
  border: 1px solid;
  background-color: var(--default-desousernamelist-background-color);
  border-color: var(--default-desousernamelist-border-color);
  font-size: calc(5px + 1vmin);
  color: white;
  text-align: left;
}
.DeSoUsernameSelectItem {
  display: flex;
  justify-content: left;
  color: white;
  text-align: left;
}

.tab-list {
  border-bottom: 1px solid rgb(130, 130, 130);
  padding-left: 0;
  font-weight: bold;
  margin-bottom: 0;
}
.tab-list-item {
  background-color: rgb(130, 130, 130);
  border: solid rgb(150, 150, 150);
  border-width: 1px 1px 0 1px;

  display: inline-block;
  list-style: none;
  margin-bottom: 0px;
  padding: 0.5rem 0.75rem;
  cursor: pointer;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}
.tab-list-active {
  background-color: rgb(100, 100, 100);
  border: solid rgb(0, 0, 0);
  border-width: 1px 1px 0 1px;
}
.tab-content {
  border: solid rgb(130, 130, 130);
  border-width: 0 1px 1px 1px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  padding-top: 20px;
  padding-bottom: 20px;
}


@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
